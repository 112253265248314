const lang = {
    en: {
        CHECK_YOUR_INBOX: `Check your inbox and click the link to confirm your subscription`,
        EMAIL: `Email`,
        ENTER_VALID_EMAIL: `Please enter a valid email address`,
        GREAT: `Great`,
        SUBSCRIBE: `Subscribe`,
        SUBSCRIBE_OVERLAY: `Stay up to date! Get all the latest & greatest posts delivered straight to your inbox`,
        SUBSCRIBE_SECTION: `Get the latest posts delivered right to your inbox`,
        SUBSCRIBE_TO: `Subscribe to`,
        YOUR_EMAIL: `youremail@example.com`,
    },
    de: {
        CHECK_YOUR_INBOX: `Öffne dein Postfach und klicke auf den Bestätigungs-Link`,
        EMAIL: `Email`,
        ENTER_VALID_EMAIL: `Bitte eine richtige Email Adresse eingeben`,
        GREAT: `Super`,
        SUBSCRIBE: `Anmelden`,
        SUBSCRIBE_OVERLAY: `Bleib auf dem Laufenden! Erhalte die neuesten Artikel direkt in dein Postfach`,
        SUBSCRIBE_SECTION: `Erhalte die neuesten Artikel direkt in dein Postfach`,
        SUBSCRIBE_TO: `Abboniere`,
        YOUR_EMAIL: `deinemail@example.de`,
    },
    lt: {
        CHECK_YOUR_INBOX: `Paspauskite ant nuorodos, kurią nusiuntėme jūsų nurodytu el. pašto adresu, kad patvirtintumėte savo registraciją`,
        EMAIL: `El. Paštas`,
        ENTER_VALID_EMAIL: `Prašome įvesti teisingą el. pašto adresą`,
        GREAT: `Puiku`,
        SUBSCRIBE: `Registruotis`,
        SUBSCRIBE_OVERLAY: `Gaukite naujausius straipsnius tiesiai į savo el. paštą`,
        SUBSCRIBE_SECTION: `Gaukite naujausius straipsnius tiesiai į savo el. paštą`,
        SUBSCRIBE_TO: `Naujienos jūsų pašte - `,
        YOUR_EMAIL: `jusupastas@gmail.com`,
    },
}

module.exports = lang