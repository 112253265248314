import React from 'react';
import PropTypes from 'prop-types';

import { ImgSharp } from 'gatsby-theme-try-ghost/src/components/common';

const Banner = ({ url, image, title }) => {
  const fluidImg =
    image && image.childImageSharp && image.childImageSharp.fluid;
  return (
    <div className={`banner-card`}>
      <a className="post-card-image-link" href={url}>
        <ImgSharp
          fluidClass="banner-card-image"
          srcClass="banner-card-image"
          fluidImg={fluidImg}
          srcImg={image}
          title={title}
        />
      </a>
    </div>
  );
};

Banner.propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default Banner;
