const lang = {
    en: {
        DARK_MODE: `DarkMode`,
    },
    de: {
        DARK_MODE: `NachtModus`,
    },
    lt: {
        DARK_MODE: `Tamsusis Rėžimas`,
    },
}

module.exports = lang